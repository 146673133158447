<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">新年寄语</div>
          <div class="notice-time">
            <div>发布时间： 2021/12/31 10:36:13</div>
            <div>浏览： 68次</div>
          </div>
          <div style="background-color: #FFF8F8;">
            <div class="notice-img-1">
              <img style="width: 100%;" src="./1.png" srcset="./1@2x.png 2x" alt="">
            </div>
            <div style="padding: 0 30px;">
              <p>
                金牛奋蹄奔大道 玉虎添翼舞新春。我们告别充满挑战、奋发有为的2021年，迎来充满希望、进取的2022年。在此，我谨代表杭州里德公司管理团队向过去一年来为公司的发展默默奉献的全体家人们表示衷心的感谢，并致以最诚挚的新年祝福。

              </p>
              <p style="margin-top: 15px;">
                过去的一年，我们有过艰辛，也有过坎坷。但面对各种经济及环境不确定因素影响，我们没有怯步，依然保持着快步小跑的基因，持续优化创新。
              </p>
              <p style="margin-top: 15px;">
                回首2021，我们全体家人以自强不息之心，矢志不渝之志，精益求精之行，追求卓越之为。不仅在代工业务上取得了众多骄人的业绩，而且在BMS行业亦获得良好的口碑和信誉。

              </p>
              <p style="margin-top: 15px;">
                展望2022年，机遇与挑战同在，拼搏与理想共存。我们在品尝美酒、分享喜悦的同时，还要清醒地认识到：危机与机遇同在。机遇蕴含精彩，创新成就伟业。新的一年开启新的希望，新的历程承载新的梦想。2022年，正是我们创造梦想、扬帆启航的关键之年。让我们一起迎难而上，锐意进取，团结拼搏，携手前行。

              </p>
              <p style="margin-top: 15px;">
                最后，再次感谢大家为里德的付出和努力。
              </p>

              <div style="margin-top: 15px;">
                <p style="margin-top: 50px;">祝：</p>
                <p>大家元旦快乐，工作顺利，身体健康，阖家幸福！</p>
              </div>
              <div class="btmflex">
                <div class="sonbtmflex">
                  <div>董事总经理</div>
                  <div style="margin-top: 8px;">徐龙忠</div>
                  <div style="margin-top: 20px;">2021年12月31日</div>
                </div>
              </div>
            </div>

            <div style="height: 100px;">
            </div>

          </div>

          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice9">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice7">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
    .btmflex {
      height: 50px;
      margin-top: 60px;
      display: flex;
      justify-content: right;
      .sonbtmflex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
